var cryptoUtils = require('./crypto/utils')
var baseX = require('base-x')

var ALLOWED_CHARS = 'rpshnaf39wBUDNEGHJKLM4PQRST7VWXYZ2bcdeCg65jkm8oFqi1tuvAxyz'

var codec = baseX(ALLOWED_CHARS)
var regexp = new RegExp('^r[' + ALLOWED_CHARS + ']{24,34}$')

module.exports = {
  /**
     * ripple address validation
     */
  isValidAddress: function (address) {
    if (regexp.test(address)) {
      return this.verifyChecksum(address)
    }

    return false
  },

  verifyChecksum: function (address) {
    var bytes = codec.decode(address)
    var computedChecksum = cryptoUtils.sha256Checksum(cryptoUtils.toHex(bytes.slice(0, -4)))
    var checksum = cryptoUtils.toHex(bytes.slice(-4))

    return computedChecksum === checksum
  }
}
